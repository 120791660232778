/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Controller } from 'stimulus'
import { createApp, App } from 'vue'
import SearchableSelectBox from '~/components/molecules/form/SearchableSelectBox.vue'
import formable from '~/components/mixins/formable'
import axios from '~/lib/axios'

export default class extends Controller {
  static targets = ['image', 'editableCardOnly']

  editableCardOnlyTargets!: HTMLInputElement[]

  declare readonly imageTarget: HTMLImageElement

  vueInstance: App | null = null

  connect() {
    const targetPrizableId = document.getElementById(
      'prizable_type'
    ) as HTMLInputElement
    if (targetPrizableId.value === 'Oripa::Card') {
      this.loadPrizeableCardsSelect()
    }
    this.updateEditbleCardOnlyDisabled(targetPrizableId.value === 'Coupon')
  }

  changePrizableId(e: Event) {
    if (!(e.currentTarget instanceof HTMLSelectElement)) return
    const { currentTarget } = e

    const targetCouponId = currentTarget.dataset.targetCouponId || ''
    const couponIdTarget = document.getElementById(targetCouponId)

    if (!currentTarget.value || !(couponIdTarget instanceof HTMLInputElement))
      return

    const isCoupon = currentTarget.value === 'Coupon'
    const prizeableSelectBox = document.querySelector('#select-box')

    if (isCoupon) {
      this.activeField(couponIdTarget)
      this.imageTarget.classList.add('d-none')
      if (prizeableSelectBox instanceof HTMLElement) {
        if (this.vueInstance) {
          this.vueInstance.unmount()
          this.vueInstance = null
        }
      }
    } else {
      this.inactiveField(couponIdTarget)
      this.loadPrizeableCardsSelect()
      this.imageTarget.classList.remove('d-none')
    }

    this.updateEditbleCardOnlyDisabled(isCoupon)
  }

  updateEditbleCardOnlyDisabled(disabled: boolean) {
    this.editableCardOnlyTargets.forEach((editableCardOnlyTarget) => {
      editableCardOnlyTarget.disabled = disabled
    })
  }

  activeField(target: HTMLElement) {
    target.setAttribute('name', 'oripa_prize[prizable_id]')
    target.setAttribute('required', 'required')
    target.classList.remove('d-none')
  }

  inactiveField(target: HTMLElement) {
    target.setAttribute('name', '')
    target.removeAttribute('required')
    target.classList.add('d-none')
  }

  loadPrizeableCardsSelect(): void {
    const el = document.querySelector('#prizeable-select-box')
    if (!el || !(el instanceof HTMLElement)) {
      return
    }

    const propsStr = el.dataset.props || '{}'
    const propsData = JSON.parse(propsStr)
    // eslint-disable-next-line prefer-const
    let filiterOptions = propsData.options
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const stimulusController = this

    this.vueInstance = createApp({
      components: {
        SearchableSelectBox,
      },
      mixins: [formable],
      data() {
        return {
          propsData: {
            name: propsData.name,
            selected: propsData.selected,
          },
          filterOptions: filiterOptions,
          selected: propsData.selected[0]?.id,
        }
      },
      mounted() {
        this.handleSelectChange(propsData.selected[0])
      },
      methods: {
        handleSelectChange(value: { id: number; data: { src: string } }) {
          const img = stimulusController.imageTarget
          if (value && value.data?.src) {
            img.src = value.data.src
            this.selected = value.id
          } else {
            img.src = ''
          }
        },
        async hundleSearch(search: string) {
          if (search.length < 1) {
            return
          }
          const url = `/administration/oripa/cards.json?q[title]=${search}`
          const response = await axios.get(url)
          const options = response.data
          this.filterOptions = options
        },
      },
      template: `
        <div>
          <searchable-select-box :name="propsData.name" :options="filterOptions" :selected="propsData.selected" optionLabel="name"
            id="select-box" @input="handleSelectChange" @search="hundleSearch" />
          <form-input type="hidden" :name="propsData.name" :modelValue="selected" />
        </div>
        `,
    })
    this.vueInstance.mount(el)
  }

  submit(e: SubmitEvent): void {
    const node = document.querySelector<HTMLElement>('.vs__selected')
    if (node === null) {
      return
    }
    const cardTitle = node.innerText
    if (!cardTitle.match(/PSA/)) {
      return
    }
    if (cardTitle.match(/PSA10/)) {
      return
    }
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('PSA10ではないPSAカードを選んでいます。間違いないですか？')) {
      return
    }
    e.preventDefault()
    const button = e.submitter
    if (button === null) {
      return
    }
    button.removeAttribute('data-disable-with')
  }
}
