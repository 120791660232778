import { Controller } from 'stimulus'
import { createApp } from 'vue'
import { uploadImage } from '~/api/administrators/upload_image'
import { oripaLotteryBodyTemplate } from '~/api/administrators/oripa_lottery_body_template'
import SearchableSelectBox from '~/components/administrators/items/SelectBoxSelector.vue'
import { nextYearEndOfDayTimestamp, currentTimestamp } from '~/core/time'

export function regExpCorrespondingToGrade(grade: string): RegExp {
  if (grade === 'first_winning') {
    return /\[oripa_prize_box.*?grade="first_winning".*?\]/
  }

  if (grade === 'second_winning') {
    return /\[oripa_prize_box.*?grade="second_winning".*?\]/
  }

  if (grade === 'third_winning') {
    return /\[oripa_prize_box.*?grade="third_winning".*?\]/
  }

  if (grade === 'fourth_winning') {
    return /\[oripa_prize_box.*?grade="fourth_winning".*?\]/
  }

  if (grade === 'fifth_winning') {
    return /\[oripa_prize_box.*?grade="fifth_winning".*?\]/
  }

  throw new Error()
}

export default class extends Controller {
  static targets = [
    'enablePrizeConvertable',
    'prizeRefuseShippingInput',
    'prizeNotConvertableInput',
    'prizeConvertableInput',
    'publishedAtInput',
    'closedAtInput',
  ]

  enablePrizeConvertableTarget!: HTMLInputElement

  prizeRefuseShippingInputTarget!: HTMLInputElement

  prizeNotConvertableInputTarget!: HTMLInputElement

  prizeConvertableInputTargets!: HTMLInputElement[]

  publishedAtInputTarget!: HTMLInputElement

  closedAtInputTarget!: HTMLInputElement

  connect(): void {
    this.loadSeriesSelect()
    this.updatePrizeConvertableDisabled()
  }

  processImage(e: Event): void {
    if (!(e.currentTarget instanceof HTMLInputElement)) return
    const { currentTarget } = e
    const targetBodyId = currentTarget.dataset.targetBodyId || ''
    const targetGradeId = currentTarget.dataset.targetGradeId || ''
    const targetAmountId = currentTarget.dataset.targetAmountId || ''
    const textAreaTarget = document.getElementById(targetBodyId)
    const selectorTarget = document.getElementById(targetGradeId)
    const amountTarget = document.getElementById(targetAmountId)

    if (
      !currentTarget.files ||
      !(textAreaTarget instanceof HTMLTextAreaElement) ||
      !(selectorTarget instanceof HTMLSelectElement) ||
      !(amountTarget instanceof HTMLInputElement)
    )
      return

    uploadImage(currentTarget.files[0])
      .then((response) => {
        if (!response.data.image_url) {
          // eslint-disable-next-line no-alert
          alert('画像のアップロードに失敗しました')
          return
        }

        if (textAreaTarget.value) {
          const shortCodeExp = regExpCorrespondingToGrade(selectorTarget.value)
          const shortCodeMatchTexts = textAreaTarget.value.match(shortCodeExp)
          if (shortCodeMatchTexts && shortCodeMatchTexts instanceof Array) {
            const shortCodeMatchText = shortCodeMatchTexts[0]

            const regExp = /urls="([^"]*)"/
            const matchUrlsTexts = shortCodeMatchText.match(regExp)
            if (!(matchUrlsTexts instanceof Array)) throw new TypeError()

            const matchUrlsText = matchUrlsTexts[1]
            const imageUrls = new Array(parseInt(amountTarget.value, 10))
              .fill(response.data.image_url)
              .join(',')
            const shortCode = `[oripa_prize_box urls="${matchUrlsText},${imageUrls}" grade="${selectorTarget.value}"]`
            textAreaTarget.value = textAreaTarget.value.replace(
              shortCodeMatchText,
              shortCode
            )
          } else {
            const imageUrls = new Array(parseInt(amountTarget.value, 10))
              .fill(response.data.image_url)
              .join(',')
            const shortCode = `[oripa_prize_box urls="${imageUrls}" grade="${selectorTarget.value}"]`
            const value = `${textAreaTarget.value}\n\n${shortCode}`
            textAreaTarget.value = value
          }
        } else {
          const imageUrls = new Array(parseInt(amountTarget.value, 10))
            .fill(response.data.image_url)
            .join(',')
          const shortCode = `[oripa_prize_box urls="${imageUrls}" grade="${selectorTarget.value}"]`
          const value = shortCode
          textAreaTarget.value = value
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('エラーが発生しました')
      })
      .finally(() => {
        currentTarget.value = ''
      })
  }

  pasteBodyFromTemplate(e: Event) {
    if (!(e.currentTarget instanceof HTMLSelectElement)) return
    const { currentTarget } = e
    const targetBodyId = currentTarget.dataset.targetBodyId || ''
    const textAreaTarget = document.getElementById(targetBodyId)

    if (
      !currentTarget.value ||
      currentTarget.value === '' ||
      !(textAreaTarget instanceof HTMLTextAreaElement)
    )
      return

    oripaLotteryBodyTemplate(currentTarget.value)
      .then((response) => {
        const bodyTemplate: string = response.data.body
        if (!bodyTemplate) {
          // eslint-disable-next-line no-alert
          alert('ボディのテンプレートの取得に失敗しました')
          return
        }

        textAreaTarget.value = textAreaTarget.value
          ? `${textAreaTarget.value}\n${bodyTemplate}`
          : bodyTemplate
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('エラーが発生しました')
      })
  }

  loadSeriesSelect(): void {
    const el = document.querySelector('#series-select-box')
    if (!el || !(el instanceof HTMLElement)) return

    const propsStr = el.dataset.props || '{}'
    const propsData = JSON.parse(propsStr)

    createApp({
      components: {
        SearchableSelectBox,
      },
      data() {
        return {
          propsData,
        }
      },
      template: '<searchable-select-box v-bind="propsData" />',
    }).mount(el)
  }

  updatePrizeConvertableDisabled(): void {
    this.prizeRefuseShippingInputTarget.disabled =
      !this.enablePrizeConvertableTarget.checked
    this.prizeNotConvertableInputTarget.disabled =
      !this.enablePrizeConvertableTarget.checked
    this.prizeConvertableInputTargets.forEach((prizeConvertableInputTarget) => {
      // eslint-disable-next-line no-param-reassign
      prizeConvertableInputTarget.disabled =
        !this.enablePrizeConvertableTarget.checked ||
        this.prizeNotConvertableInputTarget.disabled ||
        this.prizeNotConvertableInputTarget.checked
    })
  }

  publish(e: Event): void {
    e.preventDefault()
    const publishedAt = new Date(currentTimestamp())
    publishedAt.setUTCHours(publishedAt.getUTCHours() + 9)
    const [publishedAtValue] = publishedAt.toISOString().split('.')
    this.publishedAtInputTarget.value = publishedAtValue
    const closedAt = new Date(nextYearEndOfDayTimestamp())
    closedAt.setUTCHours(closedAt.getUTCHours() + 9)
    const [closedAtValue] = closedAt.toISOString().split('.')
    this.closedAtInputTarget.value = closedAtValue
  }

  unpublish(e: Event): void {
    e.preventDefault()
    const closedAt = new Date(currentTimestamp())
    closedAt.setUTCHours(closedAt.getUTCHours() + 9)
    closedAt.setUTCMinutes(closedAt.getUTCMinutes() - 1)
    const [closedAtValue] = closedAt.toISOString().split('.')
    this.closedAtInputTarget.value = closedAtValue
  }
}
